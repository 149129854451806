import { useEffect, useState } from "react";

import Layout from "./component/Layout";
import Loader from "./Loader";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 450);
  }, []);

  return <>{loading ? <Loader /> : <Layout />}</>;
}

export default App;

// https://www.sliderrevolution.com/resources/css-dropdown-menu/
// https://www.geeksforgeeks.org/make-a-div-horizontally-scrollable-using-css/
// https://www.w3.org/Style/Examples/007/center.en.tmpl
