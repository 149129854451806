import scGambar from "../img/ikon-ikon/smartcityy.png";

const Smartcity = () => {
  return (
    <div className="sc">
      <a className="sc-link" href="https://smartcity.gowakab.go.id/">
        <img src={scGambar} className="scGambar" alt="scGambar" />
      </a>
    </div>
  );
};

export default Smartcity;
