import webGambar from "../img/ikon-ikon/web.png";

const Web = () => {
  return (
    <div className="web">
      <a className="web-link" href="https://gowakab.go.id/site/">
        <img src={webGambar} className="webGambar" alt="webGambar" />
      </a>
    </div>
  );
};

export default Web;
